import React from 'react';
import type {
  GatsbySSR,
  GatsbyBrowser,
  WrapPageElementNodeArgs,
  WrapPageElementBrowserArgs
} from 'gatsby';
import { Layout } from './Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] | GatsbySSR['wrapPageElement'] = ({
  element,
  props
}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  );
};
