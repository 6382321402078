module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.15.1_gatsby@5.13.0/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.1_gatsby@5.13.0/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-i18n@3.0.0_gatsby-plugin-react-helmet@6.13.0_gatsby@5.13.0_react-dom@18.2.0_react-helmet@6.1.0_react@18.2.0/node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"prefixDefault":true,"defaultLang":"es","configPath":"/home/javier/Documents/Posada Javier/repos/apartamentosjavier/web/packages/site/i18n/config.json"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-i18n-react-i18next@3.0.0_gatsby@5.13.0_i18next@23.7.11_react-dom@18.2.0_react-i18next@13.5.0_react@18.2.0/node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"fallbackLng":["en","es"],"ns":["common"]},"order":["path","querystring","cookie","localStorage","sessionStorage","navigator","htmlTag","subdomain"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.0_gatsby@5.13.0_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apartamentos Javier","short_name":"Apartamentos Javier","start_url":"/","icon":"src/images/apartamentosjavier_favicon_web_square.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"edc9fe5fe7235536974e6930d88254f2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
