exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apartamentos-tsx": () => import("./../../../src/pages/apartamentos.tsx" /* webpackChunkName: "component---src-pages-apartamentos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-la-region-tsx": () => import("./../../../src/pages/la-region.tsx" /* webpackChunkName: "component---src-pages-la-region-tsx" */),
  "component---src-pages-politica-de-privacidad-tsx": () => import("./../../../src/pages/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-tsx" */),
  "component---src-pages-reservas-tsx": () => import("./../../../src/pages/reservas.tsx" /* webpackChunkName: "component---src-pages-reservas-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-pages-ubicacion-tsx": () => import("./../../../src/pages/ubicacion.tsx" /* webpackChunkName: "component---src-pages-ubicacion-tsx" */),
  "component---src-templates-lang-detection-page-tsx": () => import("./../../../src/templates/LangDetectionPage.tsx" /* webpackChunkName: "component---src-templates-lang-detection-page-tsx" */)
}

