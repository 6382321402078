import React from 'react';
import { useTheme, ThemeProvider } from '@mui/material/styles';

export const Layout: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const theme = useTheme();

  // Apply styled components theme provider AFTER material ui
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};
