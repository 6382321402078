import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/*
 * Fallback fonts for serif fonts are different than for sans-serif
 *   - serif fallbacks: "Times New Roman", "Roboto Slab", serif
 *   - sans-serif fallbacks: "Helvetica", "Arial", "Roboto", sans-serif
 */
const theme = responsiveFontSizes(createTheme({
  typography: {
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // h1: {
    //   font-family: '"Roboto Slab", "Times New Roman", serif',
    // },
    fontFamily: '"Source Sans 3", "Helvetica", "Arial", "Roboto", sans-serif',
    h1: {
      fontFamily: '"Libre Baskerville", "Times New Roman", "Roboto Slab", serif'
    },
    h2: {
      fontFamily: '"Libre Baskerville", "Times New Roman", "Roboto Slab", serif'
    },
    h3: {
      fontFamily: '"Libre Baskerville", "Times New Roman", "Roboto Slab", serif'
    },
    h4: {
      fontFamily: '"Libre Baskerville", "Times New Roman", "Roboto Slab", serif'
    }
    // Variable fonts turn out to be much heavier
    // fontFamily: '"Open Sans", "Helvetica", "Arial", "Roboto", sans-serif',
    // h1: {
    //   fontFamily: '"Brygada 1918", "Times New Roman", "Roboto Slab", serif'
    // },
    // h2: {
    //   fontFamily: '"Brygada 1918", "Times New Roman", "Roboto Slab", serif'
    // },
    // h3: {
    //   fontFamily: '"Brygada 1918", "Times New Roman", "Roboto Slab", serif'
    // },
    // h4: {
    //   fontFamily: '"Brygada 1918", "Times New Roman", "Roboto Slab", serif'
    // }
  },
  palette: {
    primary: { main: '#7b6557' },
    secondary: { main: '#5D737E' }
  }
}));

export default theme;
